





import { Component, Vue } from "vue-property-decorator";
import { IconsType } from "@/types";
import { mdiDownloadOutline } from "@mdi/js";
@Component
export default class CardUserUpload extends Vue {
  private downloading = false;
  private icons: IconsType = {
    download: mdiDownloadOutline
  };
  onDownload() {
    this.downloading = true;
    setTimeout(async () => {
      const res = await this.$store.dispatch("AdminModule/downloadReport");
      if (!res) this.$emit("error");
      else this.$emit("success");
      this.downloading = false;
    });
  }
}
